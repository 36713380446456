<template>
  <div class="mod-config">
    <el-form :inline="true" :model="dataForm">
      <el-form-item>
        <el-input
          style="width: 180px"
          v-model="dataForm.oid"
          placeholder="请输入订单号"
          clearable
        ></el-input>
      </el-form-item>
      <el-select
        v-model="dataForm.cid"
        clearable
        :placeholder="companyName ? companyName : '按代理商筛选'"
        filterable
        @focus="companySelectFocus"
        style="width: 180px; margin-right: 10px"
        @change="changeCompany"
      >
        <el-option
          v-for="item in companyOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="dataForm.bid"
        clearable
        placeholder="按商家筛选"
        filterable
        @focus="businessSelectFocus"
        style="width: 180px; margin-right: 10px"
      >
        <el-option
          v-for="item in businessOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        style="width: 180px; margin-right: 10px"
        v-model="dataForm.uid"
        filterable
        remote
        clearable
        reserve-keyword
        placeholder="按用户筛选"
        :remote-method="remoteManager"
        :loading="loading"
      >
        <el-option
          v-for="item in userList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="dataForm.type"
        clearable
        placeholder="按类型筛选"
        style="width: 180px; margin-right: 10px"
      >
        <el-option
          v-for="item in typeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-date-picker
        style="width: 180px; margin-right: 10px"
        v-model="dataForm.startDate"
        align="right"
        type="date"
        placeholder="选择开始日期"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd HH:mm:ss"
      >
        >
      </el-date-picker>
      <el-date-picker
        style="width: 180px; margin-right: 10px"
        v-model="dataForm.endDate"
        align="right"
        type="date"
        placeholder="选择开始日期"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd HH:mm:ss"
      >
        >
      </el-date-picker>
      <el-form-item>
        <el-button @click="getDataList()">查询</el-button>
        <!-- <el-button type="primary" @click="addOrUpdateHandle()">新增</el-button> -->
      </el-form-item>
    </el-form>
    <div>总金额：{{ totalMoney / 100 }}</div>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      style="width: 100%"
    >
      <el-table-column
        prop="id"
        header-align="center"
        align="center"
        label="编号"
      >
      </el-table-column>
      <el-table-column
        prop="unick"
        header-align="center"
        align="center"
        label="用户昵称"
      >
      </el-table-column>
      <el-table-column
        prop="cname"
        header-align="center"
        align="center"
        label="商品名"
      >
      </el-table-column>
      <el-table-column
        prop="type"
        header-align="center"
        align="center"
        label="类型"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.type == 0">商品交易</span>
          <span v-if="scope.row.type == 1">后台手动修改</span>
          <span v-if="scope.row.type == 2">提现</span>
          <span v-if="scope.row.type == 3">充值</span>
          <span v-if="scope.row.type == 4">平台抽成</span>
          <span v-if="scope.row.type == 5">商家分成</span>
          <span v-if="scope.row.type == 6">代理商分成</span>
          <span v-if="scope.row.type == 7">司机分成</span>
          <span v-if="scope.row.type == 8">平台代收</span>
          <span v-if="scope.row.type == 9">推广者佣金</span>
          <span v-if="scope.row.type == 10">司机垫付的维修费</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="moneyType"
        header-align="center"
        align="center"
        label="交易类型"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.moneyType == 0">积分交易</span>
          <span v-if="scope.row.moneyType == 1">现金交易</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="money"
        header-align="center"
        align="center"
        label="交易金额"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.money ? scope.row.money / 100 : "" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="moneyPrefix"
        header-align="center"
        align="center"
        label="交易前金额"
      >
        <template slot-scope="scope">
          <span>{{
            scope.row.moneyPrefix ? scope.row.moneyPrefix / 100 : ""
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="moneySuffix"
        header-align="center"
        align="center"
        label="交易后金额"
      >
        <template slot-scope="scope">
          <span>{{
            scope.row.moneySuffix ? scope.row.moneySuffix / 100 : ""
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createDate"
        header-align="center"
        align="center"
        label="创建时间"
      >
      </el-table-column>
      <!-- <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">修改</el-button>
          <el-button type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
        </template>
      </el-table-column> -->
    </el-table>
    <el-pagination
      style="text-align: center; margin-top: 10px"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100, 1000, 10000]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <!-- <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update> -->
  </div>
</template>

<script>
import {
  getPropertywaterList,
  getUserList,
  getBusinessList,
  getCompanyList,
} from "../../api/api";
export default {
  data() {
    return {
      totalMoney: 0,

      dataForm: {
        oid: "",
        cid: "",
        bid: "",
        uid: "",
        type: "",
        startDate: "",
        endDate: "",
      },
      //  代理商选择器列表
      companyOptions: [],
      // 代理商选择器默认展示
      companyName: "",
      // 用户选择器列表
      userList: [],
      // 商家选择器列表
      businessOptions: [],
      // 类型选择器列表
      typeOptions: [
        {
          value: 0,
          label: "商品交易",
        },
        {
          value: 1,
          label: "手动修改",
        },
        {
          value: 2,
          label: "提现",
        },
        {
          value: 3,
          label: "充值",
        },
        {
          value: 4,
          label: "平台抽成",
        },
        {
          value: 5,
          label: "商家分成",
        },
        {
          value: 6,
          label: "代理商分成",
        },
        {
          value: 7,
          label: "司机分成",
        },
        {
          value: 8,
          label: "平台代收",
        },
        {
          value: 9,
          label: "推广者佣金",
        },
        {
          value: 10,
          label: "司机垫付的维修费",
        },
      ],
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      loading: false,
    };
  },
  // components: {
  //   AddOrUpdate,
  // },
  created() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo.cid) {
      this.dataForm.cid = userInfo.company.id;
      this.companyOptions.push({
        value: this.dataForm.cid,
        label: userInfo.company.name,
      });
      this.companyName = userInfo.company.name;
    }
    this.getDataList();
  },
  methods: {
    // 商家选择框获得焦点事件
    businessSelectFocus() {
      if (this.dataForm.cid == "" || this.dataForm.cid == null) {
        this.$message.error("请先选择代理商");
        return;
      }
      this.dataListLoading = true;
      getBusinessList({ limit: 99999, cid: this.dataForm.cid }).then((data) => {
        if (data && data.code === 0) {
          let resultList = data.data.list;
          if (resultList.length > 0) {
            this.businessOptions = [];
            for (let item of resultList) {
              this.businessOptions.push({
                value: item.id,
                label: item.name,
              });
            }
          }
        }
        this.dataListLoading = false;
      });
    },
    // 选择代理商的事件
    changeCompany(value) {
      this.dataForm.bid = "";
      this.businessName = "";
      this.dataForm.cid = value;
    },
    // 代理商选择框获得焦点事件
    companySelectFocus() {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (userInfo.cid) {
        return;
      }

      this.dataListLoading = true;
      getCompanyList({ limit: 9999 }).then((data) => {
        if (data && data.code === 0) {
          let resultList = data.data.list;
          if (resultList.length > 0) {
            this.companyOptions = [];
            for (let item of resultList) {
              this.companyOptions.push({
                value: item.id,
                label: item.name,
              });
            }
          }
        }
        this.dataListLoading = false;
      });
    },
    // 搜索用户
    remoteManager(query) {
      if (query !== "") {
        this.loading = true;
        this.userList = [];
        setTimeout(() => {
          // 请求用户列表，封装数据
          let requestData = { keyword: query };
          getUserList(requestData).then((data) => {
            if (data && data.code === 0) {
              for (let item of data.data.list) {
                let userOps = {};
                userOps.value = item.id;
                userOps.label = item.nickName + "(" + item.realName + ")";
                this.userList.push(userOps);
              }
            } else {
              this.userList = [];
            }
          });
        }, 200);
        this.loading = false;
      }
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      this.dataForm.page = this.pageIndex;
      this.dataForm.limit = this.pageSize;

      getPropertywaterList(this.dataForm).then((data) => {
        if (data && data.code === 0) {
          this.totalMoney = 0;
          this.dataList = data.data.list;
          for (var item of this.dataList) {
            this.totalMoney += item.money;
          }
          this.totalPage = data.data.totalCount;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },

    // 新增 / 修改
    // addOrUpdateHandle (id) {
    //   this.addOrUpdateVisible = true
    //   this.$nextTick(() => {
    //     this.$refs.addOrUpdate.init(id)
    //   })
    // },
    // 删除
    // deleteHandle (id) {
    //   var ids = id ? [id] : this.dataListSelections.map(item => {
    //     return item.id
    //   })
    //   this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(() => {
    //     this.$http({
    //       url: this.$http.adornUrl('/car/propertywater/delete'),
    //       method: 'post',
    //       data: this.$http.adornData(ids, false)
    //     }).then(({data}) => {
    //       if (data && data.code === 0) {
    //         this.$message({
    //           message: '操作成功',
    //           type: 'success',
    //           duration: 1500,
    //           onClose: () => {
    //             this.getDataList()
    //           }
    //         })
    //       } else {
    //         this.$message.error(data.msg)
    //       }
    //     })
    //   })
    // }
  },
};
</script>
